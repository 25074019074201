import React from "react"
import SEO from "../components/SEO"
import Template from "../components/Template"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Hero from "../components/Blog/Hero"
import Author from "../components/Blog/Author"
import Body from "../components/Blog/Body"
import Share from "../components/Blog/Share"
const BlogTemplate=(props)=>{
    const post=props.data.entrada

    let textoSegmented=[]
                post.cuerpo.forEach(child=>{
                    child.children.forEach(segmento=>{
                        textoSegmented.push(segmento.text)
                    })
                   
                })
    const texto=textoSegmented.join("")

    return(
        <Template>
            <SEO title={post.title} description={texto}/>
            <Row className="justify-content-center">
              <Col md={8} sm={11} xs={11}><h1 style={{marginTop: "1em", marginBottom: "1em", }} className="labelFicha">{post.title}</h1>
            </Col>
            </Row>
            {post.image && (<Hero imageUrl={post.image.asset.url}/>)}
            <Row className="justify-content-center">
              <Col md={8} sm={11} >
              <Author asesor={post.asesor} fechaPublicacion={post.fechaPublicacion}/>
            </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={8} sm={11} xs={11} style={{marginTop: "2em"}}>
                <Body cuerpo={post._rawCuerpo} />
            </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={8} sm={11} xs={11} style={{marginTop: "2em"}}>
                <Share  slug={post.slug.current}/>
            </Col>
            </Row>

        </Template>
    )
}

export default BlogTemplate

export const query = graphql`
  query BlogTemplateQuery($id: String!) {
    entrada: sanityEntrada(id: {eq: $id}) {
        fechaPublicacion
        slug{
          current
        }
        asesor {
          name
          image {
            asset {
              url
            }
          }
        }
        title
        _rawCuerpo
        cuerpo{
          children{
            text
          }
        }
        image{
          asset{
            url
          }
        }
    }
  }
`