import React from "react"
const BlockContent = require('@sanity/block-content-to-react')




  

const Body =(props)=>{
    return(
        <BlockContent blocks={props.cuerpo}  />
    )
}

export default Body