import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import noImage from "../../styles/images/no-image-asesor.png"
import formatDate from "../../utils/Date"
const Author = (props) =>{
    return(
        <Row className="justify-content-start" style={{marginTop: "1em"}}>
            <Col sm={2} xs={4}>
                <img className="authorPost" src={props.asesor.image? props.asesor.image.asset.url+"?w=200" : noImage} />
            </Col>
            <Col sm={8} xs={8} style={{marginTop: "1vh"}}>
                <span>Escrito por</span><br /> 
                <span className="labelFicha">{props.asesor.name}</span> <br />
                <span>Fecha</span> <br/>
                <span className="labelFicha">{formatDate(props.fechaPublicacion)}</span>
                

            </Col>
        </Row>
    )
}

export default Author