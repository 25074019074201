import React from "react"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const Hero=(props)=>{
    return(
        <Row className="justify-content-center" >
            <Col md={8} sm={12}>

            <div className="heroContainer">
                <img className="hero" src={props.imageUrl+"?w=900"} />
            </div>
            </Col>
        </Row>
   
    )
}

export default Hero