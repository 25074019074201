import React from "react"
import useSiteMetadata from '../../hooks/useSiteMetadata';
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon
  } from "react-share";

  const rrssStyle={
      marginLeft: "2px"
  }

const Share = (props) =>{
    const { siteURL } = useSiteMetadata();
    const url=`${siteURL}/blog/${props.slug}`
    return(
    <Row>
        <Col md={12} >
        <h4 className="labelFicha">Comparte</h4>
        <span>
            <span><FacebookShareButton url={url}><FacebookIcon style={rrssStyle} round={true} size={32}/></FacebookShareButton></span>
            <span><WhatsappShareButton url={url}><WhatsappIcon style={rrssStyle} round={true} size={32} /></WhatsappShareButton></span>
            <span><TwitterShareButton url={url}><TwitterIcon style={rrssStyle} round={true} size={32} /></TwitterShareButton></span>
            <span><LinkedinShareButton url={url}><LinkedinIcon style={rrssStyle} round={true} size={32} /></LinkedinShareButton></span>
            <span><EmailShareButton url={url}><EmailIcon style={rrssStyle} round={true} size={32} /></EmailShareButton></span>
        </span>
    </Col>
</Row>
    )

}
export default Share